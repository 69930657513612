import React, { FC, useEffect } from 'react';
import { Column, Row } from '../../../atoms/Layout';
import BackButton from '../../../molecules/BackButton';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { Title } from '../../../atoms/Typography';
import NavigationTabs from '../../../atoms/NavigationTabs';
import Space from './Space';
import { VerticalSpace2 } from '../../../atoms/Space';
import Password from './Password';
import FeedbackPageSettings from './FeedbackPageSettings';
import Tiles from './Tiles';
import FeedbackPagePrompt from './FeedbackPagePrompt';

const TABS = [
    {
        label: 'Space',
        to: '/dashboard/settings/space'
    },
    {
        label: 'Password',
        to: '/dashboard/settings/password'
    },
    {
        label: 'Tiles',
        to: '/dashboard/settings/tiles'
    },
    {
        label: 'Feedback page',
        to: '/dashboard/settings/feedback'
    },
    {
        label: 'Team access',
        to: '/dashboard/settings/team'
    }
]

const RedirectToSpace: FC = () => {
    const navigate = useNavigate()
    useEffect(() => {
        navigate('/dashboard/settings/space')
    }, [])

    return <div>Redirecting...</div>
}

const Settings: FC = () => {
    const navigate = useNavigate()

    return <Column size="full">
        <Row padding="medium">
            <BackButton onClick={() => navigate('/dashboard/home')} />
        </Row>
        <Row padding="medium">
            <Title>Account Settings</Title>
        </Row>
        <NavigationTabs tabs={TABS} />
        <VerticalSpace2 />
        <Routes>
            <Route path="/space" element={<Space />} />
            <Route path="/" element={<Space />} />
            <Route path="/password" element={<Password/>} />
            <Route path="/tiles" element={<Tiles />} />
            <Route path="/feedback" element={<FeedbackPageSettings />} />
            <Route path="/feedback/prompts" element={<FeedbackPagePrompt />} />
            <Route path="/team" element={<div>Coming Soon</div>} />
            <Route path="*" element={<RedirectToSpace />} />
        </Routes>
    </Column>
}

export default Settings;
