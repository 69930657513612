import React, { FC } from 'react';
import SentimentCard from '../atoms/SentimentCard';
import { HorizontalSpaceFlex1, VerticalSpaceFlex1 } from 'atoms/Space';
import { Body, Title } from 'atoms/Typography';
import { Channel, ChannelCategory } from 'api/channels';
import { Column, Row } from 'atoms/Layout';
import { useNavigate } from 'react-router-dom';
import { ArrowsPointingOut, ChatBubbleLeftEllipsis, UserCircle } from 'atoms/Icon';
import { useAppContext } from 'context/AppContext';
import { SENTIMENT_NAMES, toSentiment } from 'model/feedback';
import ClipboardIcon from '../molecules/ClipboardIcon';
import styled from 'styled-components';
import QRCodeIcon from '../molecules/QRCodeIcon';

interface Props {
    channel: Channel;
    all?: boolean;
}

const CHANNEL_CATEGORY_LABEL: Record<ChannelCategory, string> = {
    [ChannelCategory.PHYSICAL_LOCATION]: "Physical Location",
    [ChannelCategory.EVENT]: "Event",
    [ChannelCategory.COMMUNITY_OUTREACH]: "Community Outreach",
    [ChannelCategory.DIGITAL]: "Digital",
}

const RowWithMaxWidth = styled(Row)`
    max-width: 330px;
`;

const ChannelCard: FC<Props> = ({ channel, all }) => {
    const { feedback } = useAppContext()
    const navigate = useNavigate()
    const relatedFeedback = feedback.value?.filter(f => all || f.channelId === channel.id) ?? []

    const averageSentimentScore = relatedFeedback.length ? relatedFeedback.reduce((acc, f) => acc + f.analysis.sentimentScore, 0) / relatedFeedback.length : 0
    const sentiment = relatedFeedback.length ? toSentiment(averageSentimentScore) : undefined

    const goToFeedback = () => {
        if (all) {
            navigate(`/dashboard/insights`)
            return
        }
        navigate(`/dashboard/insights/${channel.id}`)
    }

    return <SentimentCard onClick={goToFeedback} sentiment={sentiment}>
        { !all && <Row gap="small">
            <HorizontalSpaceFlex1 />
            <QRCodeIcon url={`/api/channel/${channel.id}/qr`} />
            <ClipboardIcon content={`https://app.symantiq.com/feedback/channel/${channel.id}`}>Copy Feedback Page URL</ClipboardIcon>
        </Row>}
        <VerticalSpaceFlex1 />
        { all && <Row>
            <ArrowsPointingOut width={45} height={45} />
        </Row>}
        <RowWithMaxWidth>
            <Title>{channel.name}</Title>
        </RowWithMaxWidth>
        { /*!all && <Row>
            <Body>
                {CHANNEL_CATEGORY_LABEL[channel.category]}
            </Body>
        </Row>*/}
        <Row>
            <Column size="full" gap="small">
                <Row vcenter gap="small">
                    <UserCircle/>
                    <Body>{sentiment ? `${SENTIMENT_NAMES[sentiment]} sentiment` : 'No sentiment analysis yet'}</Body>
                </Row>
                <Row vcenter gap="small">
                    <ChatBubbleLeftEllipsis />
                    <Body>{relatedFeedback.length} responses</Body>
                </Row>
            </Column>
        </Row>

    </SentimentCard>
}

export default ChannelCard;
