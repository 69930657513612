import React, { FC } from 'react';
import { Column, HorizontalDivider, Row } from 'atoms/Layout';
import Panel from 'atoms/Panel';
import InsightScore from 'organisms/InsightScore';
import { useAppContext } from 'context/AppContext';
import Loading from 'atoms/Loading';
import { Label, Title } from 'atoms/Typography';
import { SENTIMENT_NAMES, toSentiment } from 'model/feedback';
import OverallSentimentBreakdown from 'organisms/OverallSentimentBreakdown';
import Accordion from '../molecules/Accordion';

interface Props {
    channelId?: string;
}

interface Score {
    title: string;
    topics: string[];
    description: string;
    hardCodedScore?: number;
}

const DEFAULT_SCORES: Score[] = [
    {
        title: 'Product score',
        topics: [
            'amenities-and-facilities',
            'entertainment-and-activities',
            'customer-service',
            'maintenance',
        ],
        description: 'Your Product Score evaluates the quality, variety, relevance and maintenance of products or amenities available in your space, ensuring visitors or residents receive value for their time and money.'
    },
    {
        title: 'Environment score',
        topics: [
            'layout-of-space',
            'cleanliness',
            'comfort',
            'navigability-and-communication',
            'accessibility',
        ],
        description: 'Your Environment Score assesses the cleanliness, layout, ambience, and overall aesthetic appeal of your space.'
    },
    {
        title: 'Service score',
        topics: [
            'navigability-and-communication',
            'safety-and-security',
            'customer-service',
            'maintenance',
        ],
        description: 'Your Service Score measures the efficiency, friendliness and competence of staff at your space, ensuring a pleasant and smooth experience for your visitors. '
    },
    {
        title: 'Community score',
        topics: [
            'entertainment-and-activities',
            'community',
            'culture'
        ],
        description: 'Your Community Score reflects the sense of belonging, inclusivity and culture among residents and staff, taking into account factors like connection to local area, social events, shared spaces, and online communities.'
    },
    {
        title: 'Accessibility score',
        topics: [
            'maintenance',
            'accessibility',
            'amenities-and-facilities',
            'navigability-and-communication',
            'layout-of-space',
        ],
        description: 'Your Accessibility Score rates the ease of getting to and navigating your space, including signage, layout clarity and transportation options.'
    }
]

const EMPLOYEE_ENGAGEMENT_SCORES: Score[] = [
    {
        title: 'Job Satisfaction Score',
        topics: [
            'ee-autonomy',
            'ee-purpose',
            'ee-workload',
            'ee-job-security',
            'ee-psychological-safety',
            'ee-values'
        ],
        description: 'Your Job Satisfaction Score evaluates the overall satisfaction of your employees, taking into account factors like autonomy, purpose, workload, job security, psychological safety, and employee alignment with company values.'
    },
    {
        title: 'Working Relationships Score',
        topics: [
            'ee-dei',
            'ee-team-collaboration',
            'ee-social-connection',
            'ee-management',
            'ee-conflict-resolution',
            'ee-office-politics'
        ],
        description: 'Your Working Relationships Score measures the quality of relationships between employees, including diversity, equity, and inclusion, team collaboration, social connection, management, conflict resolution, and office social dynamics.'
    },
    {
        title: 'Incentives & Career Score',
        topics: [
            'ee-training-learning',
            'ee-career-growth',
            'ee-performance-reviews',
            'ee-pay-benefits',
            'ee-goals-feedback',
            'ee-recognition'
        ],
        description: 'Your Incentives & Career Score evaluates the incentives and career opportunities available to employees, including training and learning opportunities, career growth, performance reviews, pay and benefits, goal setting and feedback, and recognition.'
    },
    {
        title: 'Work-Life Balance Score',
        topics: [
            'ee-flexibile-working-arangements',
            'ee-vacations',
            'ee-wellbeing',
            'ee-hours',
            'ee-break-time',
            'ee-office'
        ],
        description: 'Your Work-Life Balance Score measures the balance between work and personal life, including flexible working arrangements, vacations, wellbeing, hours, break time, and office environment.'
    }
];

const HUMAN_RESOURCES_SCORES: Score[] = [
    {
        title: 'Engagement Score',
        topics: [
            'hr-resources',
            'hr-pay',
            'hr-management',
            'hr-communication',
            'hr-wellbeing',
        ],
        description: 'Your Engagement Score gauges the level of involvement, satisfaction, and connection employees have with their work environment, considering factors like technology, resources, pay, management, communication, well-being, and DEI initiatives.',
        hardCodedScore: 0.40
    },
    {
        title: 'Team Collaboration Score',
        topics: [
            'hr-communication',
            'hr-management',
            'hr-dei',
            'hr-wellbeing'
        ],
        description: 'Your Team Collaboration Score evaluates the effectiveness, communication, and synergy among team members, fostering a cohesive and productive work environment. This includes aspects of communication, management, DEI, and well-being.',
        hardCodedScore: 0.46
    },
    {
        title: 'Support Score',
        topics: [
            'hr-resources',
            'hr-technology',
            'hr-communication',
            'hr-management'
        ],
        description: 'Your Support Score measures the responsiveness, helpfulness, and effectiveness of support services and resources available to employees, ensuring they have the assistance they need to excel in their roles. This encompasses resources, technology, communication, and management support.',
        hardCodedScore: 0.20
    },
    {
        title: 'Culture Score',
        topics: [
            'hr-dei',
            'hr-management',
            'hr-communication',
            'hr-wellbeing'
        ],
        description: 'Your Culture Score reflects the shared values, inclusivity, and sense of community within the workplace, encompassing factors like team dynamics, social events, and diversity initiatives. This includes DEI, management, communication, and well-being.',
        hardCodedScore: 0.35
    },
    {
        title: 'Accessibility Score',
        topics: [
            'hr-technology',
            'hr-resources',
            'hr-communication'
        ],
        description: 'Your Accessibility Score rates the ease of accessing resources, information, and support within the workplace, including physical accessibility and availability of tools and technology to facilitate work tasks. This includes technology, resources, and communication.',
        hardCodedScore: 0.145 // 0.18 -> 6.3
    }
];


const chooseScores = (topicListId: string) => {
    switch (topicListId) {
        case 'EMPLOYEE_ENGAGEMENT':
            return EMPLOYEE_ENGAGEMENT_SCORES;
        case 'HUMAN_RESOURCES':
            return HUMAN_RESOURCES_SCORES;
        default:
            return DEFAULT_SCORES;
    }

}

const InsightScores: FC<Props> = ({ channelId }) => {
    const { feedback, channels } = useAppContext();
    const channel = channels.value?.find(c => c.id === channelId);
    const relatedFeedback = feedback.value?.filter(f => !channelId || f.channelId === channelId) ?? []

    const filterFeedbackByTopic = (topics: string[]) => {
        // Return only feedback that has a topic that matches one of the topics in the array
        return relatedFeedback.filter(f => f.analysis.topics.some(t => topics.includes(t.id)))
    }
    const averageSentiment = relatedFeedback.length ? relatedFeedback.reduce((acc, f) => acc + f.analysis.sentimentScore, 0) / relatedFeedback.length : 0;
    const sentiment = toSentiment(averageSentiment)
    const scores = chooseScores(channel?.topicListId ?? '');

    if (feedback.loading) {
        return <Column gap="large" size="half">
            <Panel>
                <Row center>
                    <Loading size="large" />
                </Row>
            </Panel>
            <Panel>
                <Row center>
                    <Loading size="large" />
                </Row>
            </Panel>
        </Column>
    }

    return <Column gap="large" size="half">
        <Panel>
            <Column gap="medium">
                <Label>Overall Sentiment</Label>
                <Row>
                    <Title>{SENTIMENT_NAMES[sentiment]}</Title>
                </Row>
                <OverallSentimentBreakdown feedback={relatedFeedback} />
            </Column>
        </Panel>
        <Panel>
            <Column gap="large">
                <Accordion title="What's the Symantiq Index?">
                    Introducing our innovative scoring system: Rating building excellence by blending user voice notes with external data like reviews, social media comments and industry sources. Scored across Product, Environment, Service, Community, and Accessibility categories, it paints a holistic picture. Benchmark, monitor building health, and share specific category reports effortlessly.
                </Accordion>
                {
                    scores.map(score => <>
                        <HorizontalDivider key={`${score.title}-divider`}/>
                        <InsightScore key={score.title} title={score.title} feedback={filterFeedbackByTopic(score.topics)} description={score.description} score={score.hardCodedScore} />
                    </>)
                }
            </Column>
        </Panel>
    </Column>
}

export default InsightScores;
