import axios from 'axios';
import { FeedbackPage } from './feedbackPage';

export enum ChannelCategory {
    PHYSICAL_LOCATION = 'PHYSICAL_LOCATION',
    COMMUNITY_OUTREACH = 'COMMUNITY_OUTREACH',
    EVENT = 'EVENT',
    DIGITAL = 'DIGITAL'
}

export interface Channel {
    id: string;
    spaceId: string;
    name: string;
    category: ChannelCategory;
    prompt?: string;
    topicListId?: string;
}

export interface ChannelPublicInformation {
    feedbackPage: FeedbackPage;
    prompt?: string;
    logoOverrideUrl?: string;
}

export const getChannels = async (): Promise<Channel[]> => {
    return (await axios.get('/api/channels')).data
}

export const createChannel = async (name: string, category: ChannelCategory): Promise<Channel> => {
    return (await axios.post('/api/channel/create', { name, category })).data
}

export const updateChannel = async (id: string, name: string, category: ChannelCategory, prompt?: string): Promise<Channel> => {
    return (await axios.post(`/api/channel/${id}/update`, { name, category, prompt })).data
}

export const getChannelPublicInformation = async (channelId: string): Promise<ChannelPublicInformation | null> => {
    try {
        return (await axios.get(`/api/channel/public/${channelId}`)).data
    } catch (error) {
        if (axios.isAxiosError(error) && error.response?.status === 404) {
            return null
        }
        throw error
    }
}
