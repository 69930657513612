import React, { FC, useEffect, useState } from 'react';
import PhonePreview from '../../../atoms/PhonePreview/index';
import {
    FeedbackPage,
    FeedbackPageAnonymizeSetting,
    FeedbackPageStyle,
    getFeedbackPageLogoSrc,
    updateFeedbackPage
} from '../../../api/feedbackPage';
import { Column, Row } from '../../../atoms/Layout';
import { BodyBold, Hint, Label, LabelBold, LinkButton } from '../../../atoms/Typography';
import RadioGroup, { RadioOption } from '../../../molecules/RadioGroup';
import { useAppContext } from '../../../context/AppContext';
import Loading from '../../../atoms/Loading';
import ColorPicker from '../../../molecules/ColorPicker';
import Button from '../../../atoms/Button';
import FileUpload from '../../../molecules/FileUpload';
import Checkbox from '../../../atoms/Checkbox';
import { useNavigate } from 'react-router-dom';
import { toFeedbackTheme } from '../../../feedbackPage/theme';
import FeedbackWizard from '../../../feedbackPage/templates/FeedbackWizard';

const STYLES: RadioOption[] = [
    {
        label: 'Dark Mode',
        value: FeedbackPageStyle.DARK
    },
    {
        label: 'Light Mode',
        value: FeedbackPageStyle.LIGHT
    }
];

const ANONYMIZE_OPTIONS: RadioOption[] = [
    {
        label: 'Don\'t anonymize',
        value: FeedbackPageAnonymizeSetting.NEVER
    },
    {
        label: 'Ask the guest if they want to anonymize',
        value: FeedbackPageAnonymizeSetting.OPTIONAL
    },
    {
        label: 'Always anonymize',
        value: FeedbackPageAnonymizeSetting.ALWAYS
    }
];

const FeedbackPageSettings: FC = () => {
    const feedbackPage = useAppContext().feedbackPage;
    const navigate = useNavigate();

    const [style, setStyle] = useState<FeedbackPageStyle>(FeedbackPageStyle.LIGHT);
    const [primaryColor, setPrimaryColor] = useState<string>('#4949CC');
    const [logoUrl, setLogoUrl] = useState<string | null>(null);

    const [logoFile, setLogoFile] = useState<File | null>(null);

    const [askPromoterScore, setAskPromoterScore] = useState<boolean>(false);
    const [anonymize, setAnonymize] = useState<FeedbackPageAnonymizeSetting>(FeedbackPageAnonymizeSetting.NEVER);

    const [saving, setSaving] = useState<boolean>(false);
    const [saved, setSaved] = useState<boolean>(false);
    const [previewKey, setPreviewKey] = useState<number>(0);


    useEffect(() => {
        void feedbackPage.load()
    }, [])

    useEffect(() => {
        if (feedbackPage.value) {
            setStyle(feedbackPage.value.style)
            setPrimaryColor(feedbackPage.value.primaryColor)
            if (feedbackPage.value.hasLogo) {
                setLogoUrl(getFeedbackPageLogoSrc(feedbackPage.value.spaceId))
            }
            setAskPromoterScore(feedbackPage.value.askPromoterScore)
            setAnonymize(feedbackPage.value.anonymize)
        }
    }, [feedbackPage.value])

    const onLogoUpload = (file: File | undefined) => {
        setLogoFile(file ?? null)
        setLogoUrl(file ? URL.createObjectURL(file) : null)
    }

    const onSubmit = async () => {
        if (!feedbackPage.value) {
            return;
        }

        setSaving(true);
        setSaved(false);

        const updatedPage = await updateFeedbackPage(
            style,
            primaryColor,
            feedbackPage.value.hasLogo && !logoUrl,
            anonymize,
            askPromoterScore,
            logoFile ?? undefined)
        feedbackPage.setValue(updatedPage)

        setSaving(false);
        setSaved(true);
    }

    const page: FeedbackPage = {
        spaceId: "",
        style,
        primaryColor,
        hasLogo: logoUrl !== null,
        askPromoterScore,
        anonymize,
        isDefault: false
    }

    const theme = toFeedbackTheme(page, undefined, logoUrl ?? undefined, logoUrl === null)

    if (feedbackPage.loading) {
        return <Row center>
            <Loading size="large" />
        </Row>
    }

    return <Row>
        <Column size="full" padding="medium" gap="medium" style={{ maxWidth: '400px', minWidth: '300px'}}>
            <BodyBold>What your guests will see when they open the feedback link</BodyBold>
            <LabelBold>Feedback Question / Prompt</LabelBold>
            <LinkButton onClick={() => navigate('/dashboard/settings/feedback/prompts')}>Edit Feedback Questions</LinkButton>
            <LabelBold>Mode</LabelBold>
            <RadioGroup value={style} options={STYLES} onChange={value => setStyle(value as FeedbackPageStyle)} />
            <LabelBold>Colour</LabelBold>
            <ColorPicker color={primaryColor} onChange={setPrimaryColor}/>
            <LabelBold>Anonymize Feedback</LabelBold>
            <Hint>
                This setting will distort the guest's voice to avoid them being identified.  Guests who know that they cannot be identified are more likely to leave honest and valuable feedback.
            </Hint>
            <RadioGroup value={anonymize} options={ANONYMIZE_OPTIONS} onChange={value => setAnonymize(value as FeedbackPageAnonymizeSetting)} />
            <LabelBold>Additional Data</LabelBold>
            <Row vcenter gap="small">
                <Checkbox value={askPromoterScore} onChange={setAskPromoterScore} />
                <Label>Ask for Net Promoter Score</Label>
            </Row>

            <LabelBold>Upload your company logo</LabelBold>
            <Hint>
                This will be used to personalize your feedback page
                <ul>
                    <li>Must be a transparent logo that is visible on either a black or white background</li>
                    <li>PNG format</li>
                    <li>Max size 2MB</li>
                </ul>
            </Hint>
            <FileUpload value={logoFile ?? undefined} onChange={onLogoUpload} />
            { saved && <Hint>Changes Saved!</Hint>}
            <Row>
                <Button onClick={onSubmit} loading={saving}>Save changes</Button>
            </Row>
        </Column>
        <Column gap="medium">
            <PhonePreview>
                <FeedbackWizard theme={theme} key={previewKey} onSubmit={async (value) => console.log(value)} />
            </PhonePreview>
            <Row center>
                <LinkButton onClick={() => setPreviewKey(prev => prev + 1)}>Refresh Preview</LinkButton>
            </Row>
        </Column>
    </Row>
}

export default FeedbackPageSettings;
