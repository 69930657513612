import { FeedbackResponse } from 'api/feedback';
import moment, { Moment } from 'moment';

export enum Sentiment {
    POSITIVE = 'POSITIVE',
    NEGATIVE = 'NEGATIVE',
    NEUTRAL = 'NEUTRAL'
}

export const toSentiment = (score: number): Sentiment => {
    if (score > 0.2) {
        return Sentiment.POSITIVE
    } else if (score < -0.2) {
        return Sentiment.NEGATIVE
    } else {
        return Sentiment.NEUTRAL
    }
}

export const SENTIMENT_NAMES: Record<Sentiment, string> = {
    [Sentiment.POSITIVE]: 'Positive',
    [Sentiment.NEGATIVE]: 'Negative',
    [Sentiment.NEUTRAL]: 'Neutral'
}

export const SENTIMENT_COLORS = {
    [Sentiment.NEGATIVE]: '#FFABAB',
    [Sentiment.NEUTRAL]: '#FFD186',
    [Sentiment.POSITIVE]: '#8FD8BF',
}

export interface FeedbackFilter {
    search?: string
    sentiments?: Sentiment[]
    channelIds?: string[],
    startDate: Moment,
    endDate: Moment
}

export const filterFeedback = (feedback: FeedbackResponse[], filter: FeedbackFilter): FeedbackResponse[] => {
    return feedback.filter(f => {
        if (filter.search && !f.analysis.textContent.toLowerCase().includes(filter.search.toLowerCase())) {
            return false
        }
        if (filter.sentiments && !filter.sentiments.includes(toSentiment(f.analysis.sentimentScore))) {
            return false
        }
        if (moment(f.createdAt).isBefore(filter.startDate)) {
            return false
        }
        if (moment(f.createdAt).isAfter(filter.endDate)) {
            return false
        }
        return !(filter.channelIds && !filter.channelIds.includes(f.channelId));

    })
}
