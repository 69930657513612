import axios from 'axios';

export interface TextFeedbackSubmission {
    text: string
    promoterScore?: number
}

export interface VoiceNoteFeedbackSubmission {
    blob: Blob,
    anonymize: boolean
    promoterScore?: number
}

export type FeedbackSubmission = TextFeedbackSubmission | VoiceNoteFeedbackSubmission

export interface FeedbackTopic {
    id: string
    name: string
    description: string
}

export enum Emotion {
    HAPPY = 'HAPPY',
    SAD = 'SAD',
    NEUTRAL = 'NEUTRAL',
    ANGRY = 'ANGRY'
}

// The AI only supports these genders
export enum Gender {
    MALE = 'MALE',
    FEMALE = 'FEMALE'
}

type LanguageCode = string;

export interface AnalysisResult {
    textContent: string
    sentimentScore: number // -1 to 1
    age?: number
    emotion?: Emotion
    gender?: Gender
    topics: FeedbackTopic[],
    language: LanguageCode
}


export enum FeedbackResponseType {
    TEXT = 'TEXT',
    VOICE = 'VOICE',
}

export interface FeedbackResponse {
    id: string
    spaceId: string
    channelId: string
    createdAt: string
    promoterScore: number | null,
    type: FeedbackResponseType,
    analysis: AnalysisResult
}

export const submitFeedback = async (channelId: string, submission: FeedbackSubmission): Promise<void> => {
    if ('text' in submission) {
        await axios.post(`/api/public/feedback/${channelId}/text`, { text: submission.text, promoterScore: submission.promoterScore })
    } else {
        const formData = new FormData();
        formData.append('voice', submission.blob, 'voice.wav');
        formData.append('anonymize', submission.anonymize ? 'true' : 'false');
        if (submission.promoterScore) {
            formData.append('promoterScore', submission.promoterScore.toString());
        }
        await axios.post(`/api/public/feedback/${channelId}/voice`, formData)
    }
}

export const getAllFeedback = async (): Promise<FeedbackResponse[]> => {
    const response = await axios.get(`/api/feedback`)
    return response.data
}

export const getFeedbackAudio = async (feedbackId: string): Promise<Blob> => {
    const response = await axios.get(`/api/feedback/${feedbackId}/audio`, { responseType: 'blob' })
    return response.data
}

export const anonymizeAudio = async (blob: Blob): Promise<Blob> => {
    const formData = new FormData();
    formData.append('voice', blob, 'voice.wav');
    const response = await axios.post(`/api/public/feedback/anonymize/webm`, formData, { responseType: 'blob' })
    return response.data
}
