import { ReactComponent as ChevronLeftComponent } from './ChevronLeft.svg'
import { ReactComponent as ArrowUpTrayComponent } from './ArrowUpTray.svg'
import { ReactComponent as XMarkComponent } from './XMark.svg'
import { ReactComponent as ChevronDownComponent } from './ChevronDown.svg'
import { ReactComponent as ChevronUpComponent } from './ChevronUp.svg'
import { ReactComponent as ArrowsPointingOutComponent } from './ArrowsPointingOut.svg'
import { ReactComponent as ChatBubbleLeftEllipsisComponent } from './ChatBubbleLeftEllipsis.svg'
import { ReactComponent as UserCircleComponent } from './UserCircle.svg'
import { ReactComponent as PlusCircleComponent } from './PlusCircle.svg'
import { ReactComponent as ArrowTopRightOnSquareComponent } from './ArrowTopRightOnSquare.svg'
import { ReactComponent as CheckComponent } from './Check.svg'
import { ReactComponent as SparklesComponent } from './Sparkles.svg'
import { ReactComponent as InformationCircleComponent } from './InformationCircle.svg'
import { ReactComponent as LinkComponent } from './Link.svg'
import { ReactComponent as PencilComponent } from './Pencil.svg'
import { ReactComponent as QRCodeComponent } from './QRCode.svg';

export const ChevronLeft = ChevronLeftComponent
export const ArrowUpTray = ArrowUpTrayComponent
export const XMark = XMarkComponent
export const ChevronDown = ChevronDownComponent
export const ChevronUp = ChevronUpComponent
export const ArrowsPointingOut = ArrowsPointingOutComponent
export const ChatBubbleLeftEllipsis = ChatBubbleLeftEllipsisComponent
export const UserCircle = UserCircleComponent
export const PlusCircle = PlusCircleComponent
export const ArrowTopRightOnSquare = ArrowTopRightOnSquareComponent
export const Check = CheckComponent
export const Sparkles = SparklesComponent
export const InformationCircle = InformationCircleComponent
export const Link = LinkComponent
export const Pencil = PencilComponent
export const QRCode = QRCodeComponent
